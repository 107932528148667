import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

function TooltipPortal({ title, text, anchorRef, visible }) {
  const [styles, setStyles] = useState({ top: 0, left: 0, opacity: 0 })
  const portalRoot = document.getElementById('tooltip-root')
  const tooltipRef = useRef(null)

  useEffect(() => {
    if (visible && anchorRef.current) {
      const rect = anchorRef.current.getBoundingClientRect()
      setStyles({
        top: rect.bottom + window.scrollY + 7,
        left: rect.left + rect.width / 2 + window.scrollX,
        opacity: 1,
      })
    } else {
      setStyles(prev => ({ ...prev, opacity: 0 }))
    }
  }, [visible, anchorRef])

  if (!portalRoot) return null
  return createPortal(
    visible && (
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          top: styles.top,
          left: styles.left,
          opacity: styles.opacity,
          transition: 'opacity 0.2s ease',
          pointerEvents: 'none',
          zIndex: 9999,
        }}
        className='desktop:w-[60vh] mobile:w-[30vh] bg-gray-600 text-white text-sm p-2 rounded shadow '
      >
        {title && <p className=' font-bold mb-[5px]'> {title}</p>}
        <p>{text}</p>
      </div>
    ),
    portalRoot
  )
}

export default TooltipPortal
