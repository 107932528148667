import { QuestionIcon } from '@components/Icons/Icons'

import React, { useRef, useState } from 'react'
import TooltipPortal from '@components/Tooltip/TooltipPortal'

export interface TooltipProps {
  title?: string
  text: string
}

const Tooltip = ({ text, title }: TooltipProps) => {
  const [hovered, setHovered] = useState(false)
  const iconRef = useRef(null)
  return (
    <div
      className='group relative'
      ref={iconRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className='rounded bg-amber-500 text-sm text-white shadow-sm'>
        <QuestionIcon />
      </div>
      <TooltipPortal
        anchorRef={iconRef}
        visible={hovered}
        title={title}
        text={text}
      />
    </div>
  )
}
export default Tooltip
