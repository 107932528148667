import { DateTime } from 'luxon'
import Table from '@components/Table/Table'
import SelectSimpleField from '@components/form/SelectSimpleField/SelectSimpleField'
import { KhotcoinTransactionPageProps } from '@features/wallet/pages/KhotcoinTransactionPage/interface'
import KhotcoinContainer from '@features/wallet/components/KhotcoinContainer/KhotcoinContainer'
import { KhotcoinTransactionTypeEnum } from '@interfaces/KhotcoinTransactionTypeEnum'
import withKhotcoinTransactionPage from './withKhotcoinTransactionPage'

const transactionType = {
  [KhotcoinTransactionTypeEnum.BOT_FEE]: 'Bot fee',
  [KhotcoinTransactionTypeEnum.TOP_UP]: 'Top up',
  [KhotcoinTransactionTypeEnum.INSURANCE]: 'Insurance',
  [KhotcoinTransactionTypeEnum.TOP_UP_BY_ADMIN]: 'Top up by ADMIN',
}

const khotcoinTransactionTypeOptions = [
  { label: 'All', value: undefined },
  { label: 'Top up', value: KhotcoinTransactionTypeEnum.TOP_UP },
  { label: 'Bot fee', value: KhotcoinTransactionTypeEnum.BOT_FEE },
  { label: 'Insurance', value: KhotcoinTransactionTypeEnum.INSURANCE },
  {
    label: 'Top up by ADMIN',
    value: KhotcoinTransactionTypeEnum.TOP_UP_BY_ADMIN,
  },
]

function KhotcoinTransactionPage({
  isLoading,
  transactions,
  page,
  perpage,
  filter,
  perpageChange,
  pageChange,
  handleFilterChanged,
}: KhotcoinTransactionPageProps) {
  return (
    <KhotcoinContainer type='transaction'>
      <div className='flex justify-between items-center mt-[16px]'>
        <div className='flex-1' />
        <div className='w-[150px]'>
          <SelectSimpleField
            isSearchable
            onSelectedChange={value => handleFilterChanged(value)}
            value={filter}
            options={khotcoinTransactionTypeOptions}
            placeholder='Sort'
          />
        </div>
      </div>
      <div className='mt-[24px]'>
        <Table
          data={transactions?.khotcoinTransactions}
          columns={[
            {
              Header: 'Date & Time',
              id: 'createdAt',
              accessor: 'createdAt',
              Cell: ({ value, row: { original } }) => (
                <span
                  className={`${
                    original.type === KhotcoinTransactionTypeEnum.BOT_FEE
                      ? 'text-red'
                      : 'text-green-100'
                  }`}
                >
                  {value
                    ? DateTime.fromMillis(value).toFormat('dd-MM-yyyy HH:mm:ss')
                    : '-'}
                </span>
              ),
              minWidth: 130,
            },
            {
              Header: 'Transaction',
              id: 'type',
              accessor: 'type',
              Cell: ({ value }) => (
                <span
                  className={
                    value === KhotcoinTransactionTypeEnum.BOT_FEE
                      ? 'text-red'
                      : 'text-green-100'
                  }
                >
                  {transactionType[value]}
                </span>
              ),
              minWidth: 90,
            },
            {
              Header: 'Amount (USDT)',
              id: 'amount',
              accessor: 'amount',
              Cell: ({ value, row: { original } }) => {
                return (
                  <span
                    className={
                      original.type === KhotcoinTransactionTypeEnum.BOT_FEE
                        ? 'text-red'
                        : 'text-green-100'
                    }
                  >
                    {original.type === KhotcoinTransactionTypeEnum.BOT_FEE
                      ? '-'
                      : ''}
                    {value?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                )
              },
              minWidth: 90,
            },
          ]}
          totalCount={transactions?.pagination?.totalCount || 0}
          totalPage={transactions?.pagination?.totalPage || 1}
          manualPagination
          currentPage={page}
          currentPageSize={perpage}
          handleOnPageChange={pageChange}
          handleOnPageSizeChange={perpageChange}
          isLoading={isLoading}
          isShowPagination
        />
      </div>
    </KhotcoinContainer>
  )
}

export default withKhotcoinTransactionPage(KhotcoinTransactionPage)
